import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/sections/GetStarted"
import heroImage from "../images/analitics-hero.png"

import image1 from '../images/analitycs-icons/ads-click.svg'
import image2 from '../images/analitycs-icons/chart-2.svg'
import image3 from '../images/analitycs-icons/status-up.svg'
import image4 from '../images/analitycs-icons/hashtag.svg'

const AnaliticsPage = () => {
  return (
    <Layout  hiddenContactBtn >
      <SEO title="analytics- Digimeth.net" />
      <article>

        <section className="typical__padding"  style={{paddingTop: `40px`, paddingBottom: `120px`}}>
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content" style={{maxWidth: `550px`}}>
                  <h2>
                    <span className="colorized">Payment data</span>
                    <br/>
                    <span className="default">and complex transaction information in one user area. What for?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="hero" src={heroImage} />
              </picture>
            </div>
          </div>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--white">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Control the customer </span>
                    <br />
                    <span className="default"> journey and analytics on your side</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
                <div className="description_box">
                <p className="description--description">
                  Digimeth provides complete control over the payment page and advanced data analysis. Why does it matter? The payment page is critical. It's the “final station” for customers making purchases. This is where the site visitor becomes the customer, bringing money to the company. And with analytics you can improve this  journey every time.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--black">
            <div className="site__container grid ">
              <div className="left">
                <div className="content--wrapper">
                  <div className="content">
                    <h2>
                      <span className="colorized">Set your business goals</span>
                      <br />
                      <span className="default" style={{color: `white`}}> based on financial insights</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="right"></div>
            </div>
            <div className="site__container grid">
            <div className="half">
              <div className="icon">
                <img src={image1} alt="cardtick" />
              </div>
              <p className="description medium white">
              We provide our clients with comprehensive payment data and detailed transaction information in a one-user area.
              </p>
            </div>
            <div className="half">
              <div className="icon">
                <img src={image2} alt="flash" />
              </div>
              <p className="description medium white">
              Based on analytics and timely, critical information, our merchants can act according to financial insights
              </p>
            </div>
            <div className="half">
              <div className="icon">
                <img src={image4} alt="flash" />
              </div>
              <p className="description medium white">
              Dashboards show real-time data, which can be separated by card brand, payment method, geography, and more.
              </p>
            </div>
            <div className="half">
              <div className="icon">
                <img src={image3} alt="flash" />
              </div>
              <p className="description medium white">
              These tools are right in your personal account to help provide customer-oriented payment experience and grow sales!
              </p>
            </div>
          </div>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--grey mt-100">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Data-driven approach</span>
                    <span className="default"> automates your business and cash flows.</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
              <br/>
              <p className="green_box--description">
                Our partners control fees, interests, withdrawals, balances, chargebacks, etc - all in the personal account. Important information allows you to stand out from the competition!
              </p>
              <br/>
            </div>
          </div>
        </section>

        <GetStarted isGetStarted={true} title="Data analysis allows you to stand out from the competition!"/>

      </article>
    </Layout>
  )
}

export default AnaliticsPage